import { Component, OnInit, Injector, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AvailabilityStructuresRequestDto, GuestCompositionDto, LocationDto, TripItemTypeDto, UserProfileDto } from '@app/api/models';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { HotelService } from '@app/api/services';
import { TRIP_ITEM_TYPE } from '@app/shared/services/trip/trip-history.token';
import { HotelAvailabilityRequest, HotelAvailabilityRequestModel } from '@app/shared/models/form-interface/hotel-availability-request.models';
import { MatDialog } from '@angular/material/dialog';
import { GuestCompositionDialogComponent } from './guest-composition-dialog/guest-composition-dialog.component';
import { BaseSearchFormComponent } from '@app/shared/base/base-search-form.component';
import { SubmitButtonModel } from '@app/shared/components/submit-button/submit-button.model';
import { HotelSearchPreset } from '@app/shared/models/form-interface/hotel-search-preset.models';
import { RiskCountryConfigInput } from '@app/shared/models/risk-country-config-input';
import { TravelerTypeService } from '@app/shared/services/traveler-type/traveler-type.service';

@Component({
	selector: 'app-form-search-hotel',
	templateUrl: './form-search-hotel.component.html',
	styleUrls: ['./form-search-hotel.component.scss'],
	providers: [
		{
			provide: TRIP_ITEM_TYPE,
			useValue: TripItemTypeDto.Hotel,
		},
	],
})
export class FormSearchHotelComponent extends BaseSearchFormComponent<HotelAvailabilityRequest> implements OnInit {
	@Input() inputPreset: HotelSearchPreset;
	@Input() detailMode: boolean = false;
	@Output() onFormSubmit: EventEmitter<AvailabilityStructuresRequestDto> = new EventEmitter<AvailabilityStructuresRequestDto>();
	@Output() onExecuteSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
	form: FormGroup<HotelAvailabilityRequest>;
	countryRiskInfoConfig: RiskCountryConfigInput;
	passengersError : boolean = false;

	constructor(injector: Injector, private dialog: MatDialog, private hotelService: HotelService, private travelerTypeService: TravelerTypeService) {
		super(injector);		
	}

	ngOnInit() {
		this.form = this.fb.group<HotelAvailabilityRequest>({
			// destination: new FormControl((this.inputPreset && this.inputPreset.cityName) ? this.inputPreset.cityName : null, Validators.required),
			destination: new FormControl(this.inputPreset?.cityName, Validators.required),
			checkinDate: new FormControl(this.inputPreset?.checkInDate, Validators.required),
			checkoutDate: new FormControl(this.inputPreset?.checkOutDate, Validators.required),
			location: new FormControl({ value: this.inputPreset?.location, disabled: this.detailMode }, Validators.required),
			destinationIds: new FormControl((this.inputPreset && this.inputPreset.location) ? [this.inputPreset.location.code] : []),
			destinationInCache: new FormControl(false),
			guestComposition: new FormControl([
				{
					adults: 1,
				},
			]),
			guestIsoCountry: new FormControl(),
			latitude: new FormControl((this.inputPreset && this.inputPreset.location) ? this.inputPreset.location.latitude : null),
			longitude: new FormControl((this.inputPreset && this.inputPreset.location) ? this.inputPreset.location.longitude : null),
			travelers: new FormControl([]),
			riskLevel: new FormControl(null),
			structureId: new FormControl(null)
		});

		this.form.controls.location.valueChanges.subscribe((value) => {
			this.form.controls.destination.setValue(value.displayName);
			this.form.controls.destinationIds.setValue([value.code]);
			this.form.controls.latitude.setValue(value.latitude);
			this.form.controls.longitude.setValue(value.longitude);
		});

		if(this.feature.isEnabled("App.TravelSecurity"))
		{
			this.form.valueChanges
				.pipe(
					filter(() => {
						const destinationControl: LocationDto = this.form.controls.location.value;
						const _temp: LocationDto = {};

						return this.form.valid && typeof(destinationControl) === typeof(_temp);
					}),
					map((t: HotelAvailabilityRequestModel) =>  {
						return {
							location: t.location,
							checkinDate: t.checkinDate,
							checkoutDate: t.checkoutDate
						} as HotelAvailabilityRequestModel;
					}),
					distinctUntilChanged((a,b) => { return JSON.stringify(a) === JSON.stringify(b); })
				)
				.subscribe((val: HotelAvailabilityRequestModel) => {
					
					// Questo if ternario serve perchè il FormControl "location" è disabilitato se "this.detailMode == true" e il metodo "valueChanges" non considera i FormControl disabilitati quindi "val" non contiene quel control.
					const _location: LocationDto = val.location ? val.location : this.form.controls.location.value;
					this.countryRiskInfoConfig = new RiskCountryConfigInput();
					
					this.countryRiskInfoConfig.originCountryCode = "IT";
					this.countryRiskInfoConfig.destinationCountryCode = _location.countryCode;
					this.countryRiskInfoConfig.destinationCountryName = _location.countryName;
					this.countryRiskInfoConfig.dateFrom = this.getDateStringFormat(val.checkinDate.toString());
					this.countryRiskInfoConfig.dateTo = this.getDateStringFormat(val.checkoutDate.toString());
				});
		}
	}

	changeRoom(): void {
		const dialogRef = this.dialog.open(GuestCompositionDialogComponent, {
			minWidth: 300,
			data: {
				composition: this.form.controls.guestComposition.value,
				userProfile: this.form.controls.travelers.getRawValue(),
			},
		});

		dialogRef.afterClosed().subscribe((result: GuestCompositionDto[]) => {
			if (result) {
				this.form.controls.guestComposition.setValue(result);
			}
		});
	}


	submit(event: SubmitButtonModel): void {
		this.setCorrectTravelerType(TripItemTypeDto.Hotel);
		/* Check For Passengers Consitency */
		const formValue = this.form.getRawValue();
		const people = formValue.guestComposition.map((it) => (it.adults ?? 0) + (it.children ?? 0)).reduce((total, current) => total + current);
		const travelers = formValue.travelers.length;
		this.passengersError = people !== travelers;
		if(this.passengersError) return;
		/* Configure Tarvelers */
		if (this.isFormValid()) this._configureTravelers();

		this.onExecuteSearch.emit(true);

		if (this.detailMode) {
			this.onFormSubmit.emit(this.form.getRawValue());
			return;
		}

		this.submitInternal(
			event,
			TripItemTypeDto.Hotel,
			this.hotelService
				.startSearch_2({
					body: this.form.getRawValue(),
				})
				.pipe(map((tx) => tx.result.id))
		);
	}
	
	private _configureTravelers() {
		const formValue = this.form.getRawValue();
		const childAges = formValue.travelers
			.filter(it => it.travelerType == 'CHD' || (!it.travelerType && this.getAge(it) && this.getAge(it) < 18))
			.map(it => this.getAge(it));
		let startIdx = 0;
		formValue.guestComposition.forEach(it => {
			if (it.children) {
				it.childAges = childAges.slice(startIdx, startIdx + it.children);
				startIdx += it.children;
			} else it.childAges = []
		});
	}

	private getAge(profile: UserProfileDto): number {
		return profile.birthDate ? new Date().getFullYear() - new Date(profile.birthDate).getFullYear() : 0;
	}
}
