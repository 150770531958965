import { Component, Injector, Output, OnInit, EventEmitter, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BusinessFieldDto, OrganizationUnitDto, TripDto } from '@app/api/models';
import { CenterOfCostsService, CompanyHqService } from '@app/api/services';
import { WidgetComponentBaseComponent } from '@app/shared/common/customizable-dashboard/widgets/widget-component-base';
import { Observable, debounceTime,  filter, finalize, map, of, startWith, switchMap, tap, zip } from 'rxjs';
import { TripBusinessFieldService } from './trip-business-field.service';

@Component({
	selector: 'app-trip-business-field',
	templateUrl: './trip-business-field.component.html',
	styleUrls: ['./trip-business-field.component.scss'],
})
export class TripBusinessFieldComponent extends WidgetComponentBaseComponent implements OnInit, AfterViewInit, OnChanges {
	companyHQsAutocomplete$: Observable<OrganizationUnitDto[]>;
	companyCocsAutocomplete$: Observable<OrganizationUnitDto[]>;
	autocompleteHqIsSearching: boolean;
	autocompleteCocIsSearching: boolean;
	form: FormGroup<{ headquarter: FormControl<OrganizationUnitDto>; centerOfCost: FormControl<OrganizationUnitDto> }>;

	@Input() hqId: number;
	@Input() cocId: number;
	@Output() onBusinessFieldChange: EventEmitter<BusinessFieldDto> = new EventEmitter<BusinessFieldDto>();

	hasPermission = true;

	constructor(injector: Injector, private formBuilder: FormBuilder, private hqService: CompanyHqService, private cocService: CenterOfCostsService, private tripBusinessfieldService: TripBusinessFieldService) {
		super(injector);
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.initInternal();
	}

	ngOnInit() {
		this.hasPermission = this.permission.isGranted('Pages.Trips.CenterOfCosts');
		this.form = this.formBuilder.group({
			headquarter: new FormControl<OrganizationUnitDto>(null, { validators: Validators.required }),
			centerOfCost: new FormControl<OrganizationUnitDto>(null, { validators: Validators.required }),
		});

		this.companyHQsAutocomplete$ = this.form.controls.headquarter.valueChanges.pipe(
			startWith(''),
			tap((t) => {
				if (!t || typeof t === 'string') {
					if (this.form.controls.centerOfCost.enabled) {
						this.form.controls.centerOfCost.reset();
						this.form.controls.centerOfCost.disable();
					}
				} else {
					this.form.controls.centerOfCost.enable();
				}
			}),
			filter((t: string | OrganizationUnitDto) => typeof t === 'string' && t.length > 2),
			debounceTime(500),
			switchMap((t: string) => {
				this.autocompleteHqIsSearching = true;

				return this.hqService.getCompanyHQsAutocomplete({ search: t }).pipe(
					map((data) => data.result.items),
					finalize(() => {
						this.autocompleteHqIsSearching = false;
					})
				);
			})
		);

		this.companyCocsAutocomplete$ = this.form.controls.centerOfCost.valueChanges.pipe(
			startWith(''),
			debounceTime(500),
			switchMap((t: string | OrganizationUnitDto) => {
				const headquarterVal = this.form.controls.headquarter.value;
				if (typeof t === 'string' && t.length > 2 && headquarterVal && typeof headquarterVal === 'object') {
					this.autocompleteCocIsSearching = true;

					return this.cocService.getCentersOfCostsAutocomplete({ search: t, headquartersId: headquarterVal.id }).pipe(
						map((data: any) => data.result.items),
						finalize(() => {
							this.autocompleteCocIsSearching = false;
						})
					);
				} else {
					return of([]);
				}
			})
		);

		this.tripBusinessfieldService.businessField$.subscribe((t) => {
			this.onBusinessFieldChange.emit(t);
		});
	}
	
	ngAfterViewInit(): void {
		this.initInternal();
	}

	displaySelectedCocText(coc?: OrganizationUnitDto) {
		if (coc) {
			return `${coc.displayName} (${coc.codeUnit})`;
		}
		return undefined;
	}

	onChange(evt: MatAutocompleteSelectedEvent) {
		this.tripBusinessfieldService.setBusinessField(this.form.value);
	}

	private initInternal() {
		if (this.hasPermission) {
			const hqId = this.hqId ?? this.tripBusinessfieldService?.model?.headquarter?.id;
			const cocId = this.cocId ?? this.tripBusinessfieldService?.model?.centerOfCost?.id;

			if (hqId || cocId) {
				this.autocompleteHqIsSearching = !!hqId && !this.form.controls.headquarter.value;
				this.autocompleteCocIsSearching = !!cocId && !this.form.controls.centerOfCost.value;

				const hqObs = this.autocompleteHqIsSearching ? 
					this.hqService.getCompanyHq({ id: hqId }).pipe(map((t) => t.result)) :
					of(this.form.controls.headquarter.value);

				const cocObs = this.autocompleteCocIsSearching ?
					this.cocService.getCenterOfCosts({ id: cocId }).pipe(map((t) => t.result)) :
					of(this.form.controls.centerOfCost.value);

				
				zip(hqObs, cocObs)
				.pipe(
					finalize(() => {
						this.autocompleteHqIsSearching = false;
						this.autocompleteCocIsSearching = false;
					})
				)
				.subscribe((t) => {
					this.form.controls.headquarter.setValue(t[0]);
					this.form.controls.centerOfCost.setValue(t[1]);

					this.tripBusinessfieldService.setBusinessField(this.form.value);
				});
			}
		}
	}
}
